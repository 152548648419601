.maps-button {
    outline: none;
    border: 0;
    background-color: transparent;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 530px; 
    height: 430px;
    padding: 5px;
}

@media only screen and (max-width: 530px) {
    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        width: 310px; 
        height: 260px;
        padding: 5px;
    }
}