.slika img {
    position: absolute;
    width: 95%;
    vertical-align: bottom;
}

.top {
    animation-name: fade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    animation-direction: alternate;
}
  
@keyframes fade {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}