.map-container {
    width: 520px; 
    height: 420px;
}

.map-popup {
    width: 200px;
}

@media only screen and (max-width: 530px) {
    .map-container {
        width: 300px; 
        height: 250px;
    }
}

@media only screen and (max-width: 530px) {
    .map-popup {
        width: 100px;
    }
}