body {
    padding-top: 0px;
    padding-bottom: 20px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
    max-width: 280px;
}



/* styles for validation helpers */
.field-validation-error {
    color: #b94a48;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #b94a48;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    color: #b94a48;
}

.validation-summary-valid {
    display: none;
}

.logoImg {
    width: 100%;
    vertical-align: bottom;
}

.flagDivContainer {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
    border: 0px;
}

.flagDiv {
    width: 23%;
    margin: 0;
    padding: 0;
    display: inline-block;
    border: 0px;
}

.flagImg {
    width: 90%;
    border: 0px;
    max-width: 80px;
}

.top-flag-container {
    border: 0px;
    display: inline-block;
}

.top-flag-img {
    border: 0px;
    max-width: 35px;
}


.middleContent {
    width: 100%;
    vertical-align: top;
}


@media only screen and (max-width: 767px) {
    .middleContent {
        width: 100%;
        vertical-align: top;
        min-height: 500px;
    }
}

.welcomeIntroImg {
    width: 60%;
    margin: 0 20% 0 20%;
}

.welcomeDiv {
    text-align: center;
    width: 100%;
    /*margin-top: 15px;*/
    margin-bottom: 32px;
    margin-top: 32px;
}


.contenTable {
    border: 0;
    width: 100%;
}

.middleIntroImg {
    width: 48%;
}

.middleIntroP {
    width: 48%;
    float: left;
}

.c2h22 {
    height: 22px;
}

.w420mt20 {
    width: 100%;
    /*padding: 10px;*/
    margin-top: 5px;
}


.w49PrMt20 {
    width: 49%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.h12vsc {
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.staticMapImg {
    border: 0px;
    width: 90%;
}

#map-canvas {
    width: 100%;
}


.weatherDiv {
    width: 60%;
    margin: 20%;
    text-align: center;
}

.mapDivParent {
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.mapDiv {
    width: 550px;
    height: 550px;
    text-align: center;
}

.mapHouse {
    width: 300px;
    height: 200px;
}

@media (max-width: 1200px) {
    .mapDiv {
        width: 400px;
        height: 400px;
        text-align: center;
    }

    .mapHouse {
        width: 200px;
        height: 100px;
    }
}


@media (max-width: 600px) {
    .mapDiv {
        width: 300px;
        height: 300px;
        text-align: center;
    }
}



@media (max-width: 310px) {
    .mapDiv {
        width: 200px;
        height: 200px;
        text-align: center;
    }
}



.regulaMiddleRow {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.t20 {
    margin-top: 20px;
}

.subHeader {
    color: blue;
    border-bottom: 1px solid blue;
    margin-bottom: 20px;
    margin-top: 20px;
}

.subSubHeader {
    color: blue;
    text-align: left;
}

.contactTextBlock {
    text-align: left;
}

.subHeaderGreen {
    color: blue;
    border-bottom: 1px solid green;
    margin-bottom: 20px;
    margin-top: 20px;
}


.w75PrMt20 {
    width: 75%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.w23PrMt20 {
    width: 23%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}


.w60PrMt20 {
    width: 60%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.w34PrMt20 {
    width: 34%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}


.w33PrMt5 {
    width: 30%;
    max-width: 30%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.w33PrMt5-img {
    max-width: 30%;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.appartmentPriceTable {
    border: 1px;
    border-spacing: 0;
    padding: 0px;
}

th,
td {
    padding: 0px;
}

/* cellpadding */

.appartImages {
    /*width: 48%;*/
    border: 0;
    border-spacing: 0;
    padding: 0;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 1px;
}

.appartImagesContainer {
    width: 100%;
    padding: 0;
    border-spacing: 0;
    margin: 0;
    vertical-align: top;
}

.appartPriceImagesContainer {
    width: 100%;
    padding: 0;
    border-spacing: 0;
    margin: 0;
    vertical-align: top;
    max-width: 280px;
}

.rulerLineGreen {
    width: 100%;
    height: 2px;
    background-color: green;
}

.rulerLineCustom {
    width: 100%;
    height: 2px;
    background-color: rgb(211, 211, 211);
}

.rulerLineYellow {
    width: 100%;
    height: 2px;
    background-color: yellow;
}

.leftAlignedMiddleRow {
    width: 100%;
    margin-top: 10px;
    text-align: left;
}

.h30w100p {
    height: 30px;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}


.w16PrMt5 {
    width: 14%;
    padding: 0;
    margin: 1px;
}

.leftAlignParagraph {
    text-align: left;
}

@media (min-width: 992px) {
    .colCenterAlign {
        text-align: center;
    }
}


.colJustAlign {
    text-align: left;
}

@media (max-width: 768px) {
    .colJustAlign {
        text-align: left;
        margin-left: 0 !important;
        margin-top: 5px;
    }
}


.t10 {
    margin-top: 10px;
}

.specialTextBlcok {
    text-align: justify;
}


.iregularMiddleRow {
    margin: 5px;
}