footer {
    text-align: center;
}

.hamburger {
    background: aquamarine;
    opacity: .4;
    border: none;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    padding: 5px;
    margin-top: 10px;
}